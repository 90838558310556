<template>
	<div :class="{pwaok:pwaModel}">
		<headerBox></headerBox>

		<div class="T4LgNb " jsname="a9kxte">
			<div jsname="qJTHM" class="kFwPee">
				<div jsaction="rcuQ6b:JqRO3d" jscontroller="q4UNLc"></div>
				<div class="tU8Y5c">
					<div>
						<div class="dzkqwc">
							<div class="wkMJlb YWi3ub">
								<c-wiz jsrenderer="XMEW5d" jsshadow="" jsdata="deferred-i6"
									data-p="%.@.null,true,[&quot;com.coindozer.cner&quot;,7],3]" data-node-index="8;0"
									jsmodel="hc6Ubd" c-wiz="">
									<div class="Mqg6jb Mhrnjf">
										<img :src="detail.logoUrl" :srcset="detail.logoUrl" class="T75of nm4vBd arM4bb"
											aria-hidden="true" alt="Icon image" itemprop="image" data-atf="false"
											data-iml="970.2999999821186">
									</div>
									<div class="hnnXjf">
										<div class="Il7kR">
											<img :src="detail.logoUrl" :srcset="detail.logoUrl"
												class="T75of cN0oRe fFmL2e" aria-hidden="true" alt="Icon image"
												itemprop="image" data-atf="false" data-iml="970.2999999821186">
											<div>
												<h1 class="Fd93Bb ynrBgc xwcR9d" itemprop="name">
													<span>{{detail.name}}</span>
												</h1>
												<div class="tv4jIf">
													<div class="Vbfug auoIOc"><a
															href="/store/apps/developer?id=Ice+Hat">
															<span>{{detail.name}} Official</span></a></div>
													<div class="ulKokd">
														<div class="">
															<span class="UIuSk">Contains ads</span>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="JU1wdd">
											<div class="l8YSdd">
												<div class="w7Iutd">

													<div class="wVqUob">
														<div class="ClM7O">
															<div itemprop="starRating">
																<div class="TT9eCd"
																	aria-label="Avaliado com 3,8 de 5 estrelas">
																	{{detail.score}}
																	<i class="google-material-icons ERwvGb"
																		aria-hidden="true">star</i>
																</div>
															</div>
														</div>
														<div class="g1rdde">{{detail.touristCount}} reviews</div>
													</div>

													<div class="wVqUob">
														<div class="ClM7O">{{detail.downloadText}}</div>
														<div class="g1rdde">Downloads</div>
													</div>
													<div class="wVqUob">
														<div class="ClM7O">
															<div class="fit">{{detail.fitCount}}+</div>
														</div>
														<div class="g1rdde">
															<span itemprop="contentRating">
																<span>Rated for {{detail.fitCount}}+</span>
															</span>
															<div jscontroller="kJXwXb" jsaction="click:CnOdef"
																class="MKV5ee" role="button" tabindex="0"
																aria-label="More info about this content rating">
																<i class="google-material-icons oUaal"
																	aria-hidden="true">info</i>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div><c-data id="i6" jsdata=" W902Ue;_;14"></c-data>
								</c-wiz>
								<div class="kk2r5b">
									<!-- <div class="install">
										<a :href="detail.downloadUrl">Install</a>
									</div> -->
									<div class="edaMIf">
										<div class="bGJWSe">
											<c-wiz c-wiz="" jsrenderer="qk5AGd" class="FuSudc" jsshadow=""
												jsdata="deferred-c2362" data-p="%.@.[&quot;com.whatsapp&quot;,7],1,1]"
												data-node-index="4;0" autoupdate="" jsmodel="hc6Ubd">
												<div class="VAgTTd LMcLV">
													<div jsaction="JIbuQc:MH7vAb" jscontroller="chfSwc" jsmodel="UfnShf"
														data-item-id="%.@.&quot;com.whatsapp&quot;,7]"
														data-is-free="true"
														jslog="38052; 1:223|CAIaPQoUEhIKDGNvbS53aGF0c2FwcBABGAMQADITCK3SsZf144EDFcQf1QodYlYCnYoBDQgAEgkKBWVuLVVTEACqAkEaPwgAEhQKEgoMY29tLndoYXRzYXBwEAEYA0oTCK3SsZf144EDFcQf1QodYlYCnfoBDwoNCAASCQoFZW4tVVMQAA==; track:click,impression"
														jsdata="Ddi83b;CgYKBENBRT0=;$2464">
														<div class="u4ICaf">
															<div class="VfPpkd-dgl2Hf-ppHlrf-sM5MNb"
																data-is-touch-wrapper="true">
																<a :href="detail.downloadUrl" v-if="playLoading==1">
																	<button type="button"
                                          @click="installClick"
																		class="VfPpkd-LgbsSe VfPpkd-LgbsSe-OWXEXe-k8QpJ VfPpkd-LgbsSe-OWXEXe-dgl2Hf nCP5yc AjY5Oe DuMIQc LQeN7 MjT6xe sOCCfd brKGGd BhQfub zwjsl"
																		jscontroller="soHxf"
																		jsaction="click:cOuCgd; mousedown:UX7yZ; mouseup:lbsD7e; mouseenter:tfO1Yc; mouseleave:JywGue; touchstart:p6p2H; touchmove:FwuNnf; touchend:yfqBxc; touchcancel:JMtRjd; focus:AHmuwe; blur:O22p3e; contextmenu:mg9Pef;mlnRJb:fLiPzd"
																		data-disable-idom="true" aria-label="Install">

																		<div class="VfPpkd-Jh9lGc"></div>
																		<div class="VfPpkd-J1Ukfc-LhBDec"></div>
																		<div class="VfPpkd-RLmnJb"></div>
																		<span jsname="V67aGc" class="VfPpkd-vQzf8d"
																			aria-hidden="true">
																			<span>
																				<span itemprop="offers" itemscope=""
																					itemtype="https://schema.org/Offer">
																					<meta itemprop="url"
																						content="https://play.google.com/store/apps/details?id=com.whatsapp&amp;rdid=com.whatsapp&amp;feature=md&amp;offerId">
																					<meta itemprop="price" content="0">
																				</span>
																			</span>
																			Play</span>
																	</button>
																</a>


                                <a  v-if="playLoading==2">
                                  <button type="button"
                                          class="VfPpkd-LgbsSe VfPpkd-LgbsSe-OWXEXe-k8QpJ VfPpkd-LgbsSe-OWXEXe-dgl2Hf nCP5yc AjY5Oe DuMIQc LQeN7 MjT6xe sOCCfd brKGGd BhQfub zwjsl"
                                          jscontroller="soHxf"
                                          jsaction="click:cOuCgd; mousedown:UX7yZ; mouseup:lbsD7e; mouseenter:tfO1Yc; mouseleave:JywGue; touchstart:p6p2H; touchmove:FwuNnf; touchend:yfqBxc; touchcancel:JMtRjd; focus:AHmuwe; blur:O22p3e; contextmenu:mg9Pef;mlnRJb:fLiPzd"
                                          data-disable-idom="true" aria-label="Install"
                                  disabled
                                  >

                                    <div class="VfPpkd-Jh9lGc"></div>
                                    <div class="VfPpkd-J1Ukfc-LhBDec"></div>
                                    <div class="VfPpkd-RLmnJb"></div>
                                    <span jsname="V67aGc" class="VfPpkd-vQzf8d"
                                          aria-hidden="true">
																			<span>
																				<span itemprop="offers" itemscope=""
                                              itemtype="https://schema.org/Offer">
																					<meta itemprop="url"
                                                content="https://play.google.com/store/apps/details?id=com.whatsapp&amp;rdid=com.whatsapp&amp;feature=md&amp;offerId">
																					<meta itemprop="price" content="0">
																				</span>
																			</span>
																			Loading...</span>
                                  </button>
                                </a>

                                <a :href="playUrl" target="_blank" v-if="playLoading==3">
                                  <button type="button"
                                          class="VfPpkd-LgbsSe VfPpkd-LgbsSe-OWXEXe-k8QpJ VfPpkd-LgbsSe-OWXEXe-dgl2Hf nCP5yc AjY5Oe DuMIQc LQeN7 MjT6xe sOCCfd brKGGd BhQfub zwjsl"
                                          jscontroller="soHxf"
                                          jsaction="click:cOuCgd; mousedown:UX7yZ; mouseup:lbsD7e; mouseenter:tfO1Yc; mouseleave:JywGue; touchstart:p6p2H; touchmove:FwuNnf; touchend:yfqBxc; touchcancel:JMtRjd; focus:AHmuwe; blur:O22p3e; contextmenu:mg9Pef;mlnRJb:fLiPzd"
                                          data-disable-idom="true" aria-label="Install">

                                    <div class="VfPpkd-Jh9lGc"></div>
                                    <div class="VfPpkd-J1Ukfc-LhBDec"></div>
                                    <div class="VfPpkd-RLmnJb"></div>
                                    <span jsname="V67aGc" class="VfPpkd-vQzf8d"
                                          aria-hidden="true">
																			<span>
																				<span itemprop="offers" itemscope=""
                                              itemtype="https://schema.org/Offer">
																					<meta itemprop="url"
                                                content="https://play.google.com/store/apps/details?id=com.whatsapp&amp;rdid=com.whatsapp&amp;feature=md&amp;offerId">
																					<meta itemprop="price" content="0">
																				</span>
																			</span>
																			Play</span>
                                  </button>
                                </a>

															</div>
														</div>
													</div>
												</div>

												<c-data id="c2362"
													jsdata=" Lq9R7c;_;$2465 FMkBkf;_;$2466 VBgNxc;_;$2467 RwSwyc;_;$2468 Piyxpc;_;$2469 QhZwQc;_;$2470 ;unsupported;$2471 GU61mf;_;$2472 ;unsupported;$2473"></c-data>
											</c-wiz>
										</div>
									</div>
									<div class="OBVQ7"><c-wiz jsrenderer="zbKb5e" jsshadow="" jsdata="deferred-i8"
											data-p="%.@.]" jscontroller="aTwUve" data-node-index="2;0" jsmodel="hc6Ubd"
											c-wiz="">
											<div jsaction="JIbuQc:ga9V"
												jslog="181760; 1:16730; track:impression,click;">
												<div class="OahvPe">
													<div class="u4ICaf">
														<div class="VfPpkd-dgl2Hf-ppHlrf-sM5MNb"
															data-is-touch-wrapper="true"><button
																class="VfPpkd-LgbsSe VfPpkd-LgbsSe-OWXEXe-Bz112c-M1Soyc VfPpkd-LgbsSe-OWXEXe-dgl2Hf ksBjEc lKxP2d LQeN7 yIoKuc aLey0c"
																jscontroller="soHxf"
																jsaction="click:cOuCgd; mousedown:UX7yZ; mouseup:lbsD7e; mouseenter:tfO1Yc; mouseleave:JywGue; touchstart:p6p2H; touchmove:FwuNnf; touchend:yfqBxc; touchcancel:JMtRjd; focus:AHmuwe; blur:O22p3e; contextmenu:mg9Pef;mlnRJb:fLiPzd;"
																data-disable-idom="true" aria-label="Share">
																<div class="VfPpkd-Jh9lGc"></div>
																<div class="VfPpkd-J1Ukfc-LhBDec"></div>
																<div class="VfPpkd-RLmnJb"></div><span
																	class="VfPpkd-kBDsod y0nrBe" aria-hidden="true"><svg
																		class="f70z8e" width="24" height="24"
																		viewBox="0 0 24 24">
																		<path
																			d="M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7s-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92 1.61 0 2.92-1.31 2.92-2.92s-1.31-2.92-2.92-2.92z">
																		</path>
																	</svg></span><span jsname="V67aGc"
																	class="VfPpkd-vQzf8d"
																	aria-hidden="true">Share</span>
															</button></div>
													</div>
												</div>
												<div class="egcKT"><button
														class="VfPpkd-Bz112c-LgbsSe yHy1rc eT1oJ mN1ivc yIoKuc"
														jscontroller="soHxf"
														jsaction="click:cOuCgd; mousedown:UX7yZ; mouseup:lbsD7e; mouseenter:tfO1Yc; mouseleave:JywGue; touchstart:p6p2H; touchmove:FwuNnf; touchend:yfqBxc; touchcancel:JMtRjd; focus:AHmuwe; blur:O22p3e; contextmenu:mg9Pef;mlnRJb:fLiPzd;"
														data-idom-class="yHy1rc eT1oJ mN1ivc yIoKuc" aria-label="Share">
														<div jsname="s3Eaab" class="VfPpkd-Bz112c-Jh9lGc"></div>
														<div class="VfPpkd-Bz112c-J1Ukfc-LhBDec"></div><span
															class="VfPpkd-kBDsod y0nrBe" aria-hidden="true"><svg
																class="f70z8e" width="24" height="24"
																viewBox="0 0 24 24">
																<path
																	d="M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7s-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92 1.61 0 2.92-1.31 2.92-2.92s-1.31-2.92-2.92-2.92z">
																</path>
															</svg></span>
													</button></div>
											</div><c-data id="i8"
												jsdata=" EDxpkb;_;6 fkWDob;com.coindozer.cner,7,,10^11^13^14^19^20^49^52^69^70^73^74^75^78^79^91^92^95^96^97^100^101^106^112^137^141^146^1^129^38^103^9^43^139^47^80^145^119^151^58^59^63;13"></c-data>
										</c-wiz><c-wiz jsrenderer="Qf3l6c" jsshadow="" jsdata="deferred-i9"
											data-p="%.@.[&quot;com.coindozer.cner&quot;,7]]" data-node-index="13;0"
											autoupdate="" jsmodel="hc6Ubd" c-wiz="">
											<div jscontroller="indMcf" jsaction="JIbuQc:htvI8d"
												jslog="38005; 1:204; track:click; mutable:true">
												<div class="ukROQd">
													<div class="u4ICaf">
														<div class="VfPpkd-dgl2Hf-ppHlrf-sM5MNb"
															data-is-touch-wrapper="true"><button
																class="VfPpkd-LgbsSe VfPpkd-LgbsSe-OWXEXe-Bz112c-M1Soyc VfPpkd-LgbsSe-OWXEXe-dgl2Hf ksBjEc lKxP2d LQeN7 LS2TS aLey0c"
																jscontroller="soHxf"
																jsaction="click:cOuCgd; mousedown:UX7yZ; mouseup:lbsD7e; mouseenter:tfO1Yc; mouseleave:JywGue; touchstart:p6p2H; touchmove:FwuNnf; touchend:yfqBxc; touchcancel:JMtRjd; focus:AHmuwe; blur:O22p3e; contextmenu:mg9Pef;mlnRJb:fLiPzd;"
																jsname="zF0Oof" data-disable-idom="true"
																aria-label="Add to wishlist">
																<div class="VfPpkd-Jh9lGc"></div>
																<div class="VfPpkd-J1Ukfc-LhBDec"></div>
																<div class="VfPpkd-RLmnJb"></div><span
																	class="VfPpkd-kBDsod iCNeVd" aria-hidden="true"><svg
																		class="XkAcee" width="24" height="24"
																		viewBox="0 0 24 24">
																		<path fill-rule="evenodd" clip-rule="evenodd"
																			d="M7 3H17C18.1045 3 19 3.8955 19 5V21L12 18L5 21L5.01075 5C5.01075 3.8955 5.8965 3 7 3ZM12 15.824L17 18V5H7V18L12 15.824ZM13 7V9H15V11H13V13H11V11H9V9H11V7H13Z">
																		</path>
																	</svg></span><span jsname="V67aGc"
																	class="VfPpkd-vQzf8d" aria-hidden="true">Add to
																	wishlist</span>
															</button></div>
													</div>
												</div>
												<div class="eLyZDd"><button
														class="VfPpkd-Bz112c-LgbsSe yHy1rc eT1oJ mN1ivc LS2TS"
														jscontroller="soHxf"
														jsaction="click:cOuCgd; mousedown:UX7yZ; mouseup:lbsD7e; mouseenter:tfO1Yc; mouseleave:JywGue; touchstart:p6p2H; touchmove:FwuNnf; touchend:yfqBxc; touchcancel:JMtRjd; focus:AHmuwe; blur:O22p3e; contextmenu:mg9Pef;mlnRJb:fLiPzd;"
														data-idom-class="yHy1rc eT1oJ mN1ivc LS2TS" jsname="YMie3c"
														aria-label="Add to wishlist">
														<div jsname="s3Eaab" class="VfPpkd-Bz112c-Jh9lGc"></div>
														<div class="VfPpkd-Bz112c-J1Ukfc-LhBDec"></div><span
															class="VfPpkd-kBDsod iCNeVd" aria-hidden="true"><svg
																class="XkAcee" width="24" height="24"
																viewBox="0 0 24 24">
																<path fill-rule="evenodd" clip-rule="evenodd"
																	d="M7 3H17C18.1045 3 19 3.8955 19 5V21L12 18L5 21L5.01075 5C5.01075 3.8955 5.8965 3 7 3ZM12 15.824L17 18V5H7V18L12 15.824ZM13 7V9H15V11H13V13H11V11H9V9H11V7H13Z">
																</path>
															</svg></span>
													</button></div>
											</div><c-data id="i9" jsdata=" z3Iiic;_;12"></c-data>
										</c-wiz></div>
									<!-- <div class="OBVQ7">
										<div class="share">
											<img src="../static/share.png" alt="" class="icon">
											<span>Share</span>
										</div>
										<div class="add">
											<img src="../static/bookmark-add.png" alt="" class="icon">
											<span>Add to wishlist</span>
										</div>
									</div> -->
								</div>
<!--								<div class="vO0kpf">-->
<!--									<div class="HtY85d ">-->
<!--										<img src="../static/svg/devices.svg" alt="" class="devices">-->
<!--										<span class="AqX8Cf"><a-->
<!--												href="https://support.google.com/googleplay/?p=remote_install_error"-->
<!--												target="_blank">You don't have any devices</a></span>-->
<!--									</div>-->
<!--								</div>-->
							</div>
						</div>
					</div>
					<div class="wkMJlb YWi3ub">
						<div class="nRgZne">
							<div class="qZmL0">
								<div ssk="8:ZnF4ie-0"><c-wiz jsrenderer="UZStuc" jsshadow="" jsdata="deferred-c1"
										data-p="%.@.[&quot;com.coindozer.cner&quot;,7]]" data-node-index="5;0"
										jsmodel="hc6Ubd" c-wiz="">
										<div class="UbmABe">
											<div class="bewvKb KUSTKe" jscontroller="vrGZEc"
												jsaction="rcuQ6b:npT2md;d9N7hc:RGEGje;McwVAe:TFCKab" jsname="haAclf">
												<div class="aoJE7e qwPPwf" jsname="K9a4Re" role="list">
													<div class="ULeU3b Utde2e" role="listitem"
														v-for="(item,idx) in detail.imagesList" :key="idx">
														<div jscontroller="RQJprf" class="Atcj9b">
															<img :src="item.url" :srcset="item.url" class="T75of B5GQxf"
																alt="Screenshot image" itemprop="image"
																data-screenshot-index="0" jsaction="click:IEAdff"
																role="button" tabindex="0" load="lazy">
														</div>
													</div>
												</div>
											</div>
										</div>
										<c-data id="c1" jsdata=" W902Ue;_;$1"></c-data>
									</c-wiz>
									<c-wiz jsrenderer="JiSSTb" jsshadow="" jsdata="deferred-i11" data-p="%.@.]"
										data-node-index="3;0" autoupdate="" jsmodel="hc6Ubd" c-wiz="">
										<div jscontroller="lpwuxb" jsaction="hdtuG:CnOdef">
											<section class="HcyOxe" jscontroller="NkbkFd">
												<header class=" cswwxf">
													<div class="VMq4uf">
														<div class="EaMWib">
															<h2 class="XfZNbf">{{detail.aboutTitle}}</h2>
														</div>
														<div jsaction="JIbuQc:trigger.hdtuG">
															<button
																class="VfPpkd-Bz112c-LgbsSe yHy1rc eT1oJ QDwDD mN1ivc VxpoF"
																jscontroller="soHxf"
																jsaction="click:cOuCgd; mousedown:UX7yZ; mouseup:lbsD7e; mouseenter:tfO1Yc; mouseleave:JywGue; touchstart:p6p2H; touchmove:FwuNnf; touchend:yfqBxc; touchcancel:JMtRjd; focus:AHmuwe; blur:O22p3e; contextmenu:mg9Pef;mlnRJb:fLiPzd;"
																data-disable-idom="true"
																aria-label="See more information on About this game">
																<div jsname="s3Eaab" class="VfPpkd-Bz112c-Jh9lGc"></div>
																<div class="VfPpkd-Bz112c-J1Ukfc-LhBDec"></div>
																<img src="../static/svg/arrow.svg" alt=""
																	class="img_icon">
															</button>
														</div>
													</div>
												</header>
												<div class="SfzRHd">
													<meta itemprop="description"
														content="This is a puzzle game that allows for custom difficulty levels.">
													<div class="bARER" data-g-id="description">
														<p v-html="detail.aboutText"></p>
													</div>
													<div class="TKjAsc">
														<div>
															<div class="lXlx5">Updated on</div>
															<div class="xg1aie">{{detail.aboutTime}}</div>
														</div>
													</div>
													<div class="Uc6QCc">
														<div class="VfPpkd-dgl2Hf-ppHlrf-sM5MNb"
															data-is-touch-wrapper="true">
															<div class="VfPpkd-LgbsSe VfPpkd-LgbsSe-OWXEXe-INsAgc VfPpkd-LgbsSe-OWXEXe-dgl2Hf Rj2Mlf OLiIxf PDpWxe P62QJc LQeN7 LMoCf"
																jscontroller="nKuFpb"
																jsaction="click:cOuCgd; mousedown:UX7yZ; mouseup:lbsD7e; mouseenter:tfO1Yc; mouseleave:JywGue; touchstart:p6p2H; touchmove:FwuNnf; touchend:yfqBxc; touchcancel:JMtRjd; focus:AHmuwe; blur:O22p3e; contextmenu:mg9Pef;mlnRJb:fLiPzd;"
																data-idom-class="Rj2Mlf OLiIxf PDpWxe P62QJc LQeN7 LMoCf"
																itemprop="genre">
																<div class="VfPpkd-Jh9lGc"></div>
																<span jsname="V67aGc" class="VfPpkd-vQzf8d"
																	aria-hidden="true">Puzzle</span>
																<a class="WpHeLc VfPpkd-mRLv6 VfPpkd-RLmnJb"
																	href="/store/apps/category/GAME_PUZZLE"
																	aria-label="Puzzle" jsname="hSRGPd"></a>
																<div class="VfPpkd-J1Ukfc-LhBDec"></div>
															</div>
														</div>
													</div>
												</div>
											</section>
										</div>
										<c-data id="i11" jsdata=" W902Ue;_;14 QwEV2c;com.coindozer.cner,7;23"></c-data>
									</c-wiz>
									<c-wiz jsrenderer="QxNhAd" jslog="144114; 1:1906;track:impression;" jsshadow=""
										jsdata="deferred-i12" data-p="%.@.[&quot;com.coindozer.cner&quot;,7]]"
										data-node-index="9;0" jsmodel="hc6Ubd" c-wiz="">
										<section class="HcyOxe" jscontroller="NkbkFd">
											<header class=" cswwxf">
												<div class="VMq4uf">
													<div class="EaMWib">
														<h2 class="XfZNbf">Data safety</h2>
													</div>
													<div class="VfPpkd-Bz112c-LgbsSe yHy1rc eT1oJ QDwDD mN1ivc VxpoF"
														jscontroller="nKuFpb"
														jsaction="click:cOuCgd; mousedown:UX7yZ; mouseup:lbsD7e; mouseenter:tfO1Yc; mouseleave:JywGue; touchstart:p6p2H; touchmove:FwuNnf; touchend:yfqBxc; touchcancel:JMtRjd; focus:AHmuwe; blur:O22p3e; contextmenu:mg9Pef;mlnRJb:fLiPzd;"
														data-disable-idom="true" jslog="144113; 1:1907;track:click;">
														<div jsname="s3Eaab" class="VfPpkd-Bz112c-Jh9lGc"></div>
														<img src="../static/svg/arrow.svg" alt="" class="img_icon">
														<a class="WpHeLc VfPpkd-mRLv6"
															href="/store/apps/datasafety?id=com.coindozer.cner"
															aria-label="See more information on Data safety"
															jsname="hSRGPd"></a>
														<div class="VfPpkd-Bz112c-J1Ukfc-LhBDec"></div>
													</div>
												</div>
											</header>
											<div class="SfzRHd">Safety starts with understanding how developers collect
												and
												share your data. Data privacy and security practices may vary based on
												your use,
												region, and age. The developer provided this information and may update
												it over
												time.<div class="XGHsbd">
													<div class="wGcURe">
														<img src="https://play-lh.googleusercontent.com/iFstqoxDElUVv4T3KxkxP3OTcuFvWF5ZQQjT7aIxy4n2uaVigCCykxeG6EZV9FQ10X1itPj1oORm=s20-rw"
															srcset="https://play-lh.googleusercontent.com/iFstqoxDElUVv4T3KxkxP3OTcuFvWF5ZQQjT7aIxy4n2uaVigCCykxeG6EZV9FQ10X1itPj1oORm=s20-rw" class="T75of whEyae"
															aria-hidden="true" alt="Icon image" data-atf="false"
															data-iml="967.4000000059605">
														<div>No data shared with third parties<div
																class="jECfAf">Learn more about how developers declare sharing</div>
														</div>
													</div>
													<div class="wGcURe">
														<img src="https://play-lh.googleusercontent.com/12USW7aflgz466ifDehKTnMoAep_VHxDmKJ6jEBoDZWCSefOC-ThRX14Mqe0r8KF9XCzrpMqJts=s20-rw"
															srcset="https://play-lh.googleusercontent.com/12USW7aflgz466ifDehKTnMoAep_VHxDmKJ6jEBoDZWCSefOC-ThRX14Mqe0r8KF9XCzrpMqJts=s20-rw" class="T75of whEyae"
															aria-hidden="true" alt="Icon image" data-atf="false"
															data-iml="970.2999999821186">
														<div>This app may collect these data types<div class="jECfAf">
                              Location, Personal info and 9 others</div>
														</div>
													</div>
													<div class="wGcURe">
														<img src="https://play-lh.googleusercontent.com/W5DPtvB8Fhmkn5LbFZki_OHL3ZI1Rdc-AFul19UK4f7np2NMjLE5QquD6H0HAeEJ977u3WH4yaQ=s20-rw"
															srcset="https://play-lh.googleusercontent.com/W5DPtvB8Fhmkn5LbFZki_OHL3ZI1Rdc-AFul19UK4f7np2NMjLE5QquD6H0HAeEJ977u3WH4yaQ=s20-rw" class="T75of whEyae"
															aria-hidden="true" alt="Icon image" data-atf="false"
															data-iml="970.2999999821186">
														<div>Data is encrypted in transit</div>
													</div>
													<div class="wGcURe">
														<img src="https://play-lh.googleusercontent.com/ohRyQRA9rNfhp7xLW0MtW1soD8SEX45Oec7MyH3FaxtukWUG_6GKVpvh3JiugzryLi7Bia02HPw=s20-rw"
															srcset="https://play-lh.googleusercontent.com/ohRyQRA9rNfhp7xLW0MtW1soD8SEX45Oec7MyH3FaxtukWUG_6GKVpvh3JiugzryLi7Bia02HPw=s20-rw" class="T75of whEyae"
															aria-hidden="true" alt="Icon image" data-atf="false"
															data-iml="970.4000000059605">
														<div>You can request that data be deleted</div>
													</div>
													<div class="u4ICaf">
														<div class="VfPpkd-dgl2Hf-ppHlrf-sM5MNb"
															data-is-touch-wrapper="true">
															<div class="VfPpkd-LgbsSe VfPpkd-LgbsSe-OWXEXe-dgl2Hf ksBjEc lKxP2d LQeN7 aLey0c qQhJce"
																jscontroller="nKuFpb"
																jsaction="click:cOuCgd; mousedown:UX7yZ; mouseup:lbsD7e; mouseenter:tfO1Yc; mouseleave:JywGue; touchstart:p6p2H; touchmove:FwuNnf; touchend:yfqBxc; touchcancel:JMtRjd; focus:AHmuwe; blur:O22p3e; contextmenu:mg9Pef;mlnRJb:fLiPzd;"
																data-disable-idom="true"
																jslog="144115; 1:1908; track:click;">
																<div class="VfPpkd-Jh9lGc"></div>
																<span jsname="V67aGc" class="VfPpkd-vQzf8d"
																	aria-hidden="true">See details</span>
																<a class="WpHeLc VfPpkd-mRLv6 VfPpkd-RLmnJb"
																	href="/store/apps/datasafety?id=com.coindozer.cner"
																	aria-label="See more details on data safety"
																	jsname="hSRGPd"></a>
																<div class="VfPpkd-J1Ukfc-LhBDec"></div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</section>
										<c-data id="i12" jsdata=" kXATVe;_;24 ybgdZd;_;25 GU61mf;_;21"></c-data>
									</c-wiz>


									<div class="Jwxk6d" data-g-id="reviews">
										<div class="HJV0ef">
											<div class="P4w39d">
												<div class="Te9Tpc">
													<div>
														<div class="jILTFe">{{detail.score}}</div>
														<div aria-label="Avaliado com 3,8 de 5 estrelas" role="img"
															class="I26one">
															<span aria-hidden="true" jsname="fI6EEc" class="deXnLd"
																data-number="1" ssk="1#1" v-for="(item,idx) in 5">
																<span class="Z1Dz7b" aria-hidden="true"
																	v-if="Number(detail.score) > idx+1">
																	<svg xmlns="http://www.w3.org/2000/svg"
																		viewBox="0 0 24 24">
																		<path d="M0 0h24v24H0z" fill="none"></path>
																		<path d="M0 0h24v24H0z" fill="none"></path>
																		<path
																			d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z">
																		</path>
																	</svg>
																</span>
																<span class="Wi7KIe" aria-hidden="true"
																	v-if="Number(detail.score) < idx+1">
																	<svg xmlns="http://www.w3.org/2000/svg"
																		viewBox="0 0 24 24">
																		<path d="M0 0h24v24H0z" fill="none"></path>
																		<path d="M0 0h24v24H0z" fill="none"></path>
																		<path
																			d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z">
																		</path>
																	</svg>
																</span>
															</span>
															<!-- <span aria-hidden="true" jsname="fI6EEc" class="deXnLd"
																data-number="2" ssk="1#2">
																<span class="Z1Dz7b" aria-hidden="true">
																	<svg xmlns="http://www.w3.org/2000/svg"
																		viewBox="0 0 24 24">
																		<path d="M0 0h24v24H0z" fill="none"></path>
																		<path d="M0 0h24v24H0z" fill="none"></path>
																		<path
																			d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z">
																		</path>
																	</svg>
																</span>
															</span>
															<span aria-hidden="true" jsname="fI6EEc" class="deXnLd"
																data-number="3" ssk="1#3">
																<span class="Z1Dz7b" aria-hidden="true">
																	<svg xmlns="http://www.w3.org/2000/svg"
																		viewBox="0 0 24 24">
																		<path d="M0 0h24v24H0z" fill="none"></path>
																		<path d="M0 0h24v24H0z" fill="none"></path>
																		<path
																			d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z">
																		</path>
																	</svg>
																</span>
															</span>
															<span aria-hidden="true" jsname="fI6EEc" class="deXnLd"
																data-number="4" ssk="1#4">
																<span class="Z1Dz7b" aria-hidden="true">
																	<svg xmlns="http://www.w3.org/2000/svg"
																		viewBox="0 0 24 24">
																		<path d="M0 0h24v24H0z" fill="none"></path>
																		<path d="M0 0h24v24H0z" fill="none"></path>
																		<path
																			d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z">
																		</path>
																	</svg>
																</span>
															</span>
															<span aria-hidden="true" jsname="fI6EEc" class="deXnLd"
																data-number="5" ssk="1#5">
																<span class="Wi7KIe" aria-hidden="true">
																	<svg xmlns="http://www.w3.org/2000/svg"
																		viewBox="0 0 24 24">
																		<path d="M0 0h24v24H0z" fill="none"></path>
																		<path d="M0 0h24v24H0z" fill="none"></path>
																		<path
																			d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z">
																		</path>
																	</svg>
																</span>
															</span> -->
														</div>
														<div class="EHUI5b">{{detail.touristCount}} reviews</div>
													</div>
													<div>
														<div class="JzwBgb" role="img"
															aria-label="921 reviews com a nota 5">
															<div aria-hidden="true" class="Qjdn7d">5</div>
															<div aria-hidden="true" class="RJfYGf">
																<div class="RutFAf wcB8se"
																	:style="{width:scoreList[0]+'%'}" title="921"></div>
															</div>
														</div>
														<div class="JzwBgb" role="img"
															aria-label="121 reviews com a nota 4">
															<div aria-hidden="true" class="Qjdn7d">4</div>
															<div aria-hidden="true" class="RJfYGf">
																<div class="RutFAf wcB8se"
																	:style="{width:scoreList[1]+'%'}" title="121"></div>
															</div>
														</div>
														<div class="JzwBgb" role="img"
															aria-label="137 reviews com a nota 3">
															<div aria-hidden="true" class="Qjdn7d">3</div>
															<div aria-hidden="true" class="RJfYGf">
																<div class="RutFAf wcB8se"
																	:style="{width:scoreList[2]+'%'}" title="137"></div>
															</div>
														</div>
														<div class="JzwBgb" role="img"
															aria-label="111 reviews com a nota 2">
															<div aria-hidden="true" class="Qjdn7d">2</div>
															<div aria-hidden="true" class="RJfYGf">
																<div class="RutFAf wcB8se"
																	:style="{width:scoreList[3]+'%'}" title="111"></div>
															</div>
														</div>
														<div class="JzwBgb" role="img"
															aria-label="300 reviews com a nota 1">
															<div aria-hidden="true" class="Qjdn7d">1</div>
															<div aria-hidden="true" class="RJfYGf">
																<div class="RutFAf wcB8se"
																	:style="{width:scoreList[4]+'%'}" title="300"></div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="EGFGHd" v-for="(item,idx) in evaluate" :key="idx">
											<header class="c1bOId" jscontroller="j9sf1" jsaction="rcuQ6b:SIRhf"
												data-review-id="67aca5e7-2138-4f36-afc7-9d42f564e11e">
												<div class="YNR7H">
													<div class="gSGphe">
														<img :src="item.logoUrl" :srcset="item.logoUrl"
															class="T75of abYEib" aria-hidden="true" loading="lazy">
														<div class="X5PpBb">{{item.name}}</div>
													</div>
													<div jsaction="FzgWvd:uXu05d">
														<div class="VfPpkd-xl07Ob-XxIAqe-OWXEXe-oYxtQd"
															jscontroller="wg1P6b"
															jsaction="JIbuQc:aj0Jcf(WjL7X); keydown:uYT2Vb(WjL7X);iFFCZc:oNPcuf;Rld2oe:li9Srb"
															jsname="L2ufaf" jsshadow="" data-disable-idom="true">
															<div jsname="WjL7X" jsslot="">
																<button class="VfPpkd-Bz112c-LgbsSe yHy1rc eT1oJ mN1ivc"
																	jscontroller="soHxf"
																	jsaction="click:cOuCgd; mousedown:UX7yZ; mouseup:lbsD7e; mouseenter:tfO1Yc; mouseleave:JywGue; touchstart:p6p2H; touchmove:FwuNnf; touchend:yfqBxc; touchcancel:JMtRjd; focus:AHmuwe; blur:O22p3e; contextmenu:mg9Pef;mlnRJb:fLiPzd;"
																	data-disable-idom="true"
																	aria-label="Mais ações de avaliação"
																	aria-expanded="false" aria-haspopup="menu"
																	aria-controls="UM33ifi15">
																	<div jsname="s3Eaab" class="VfPpkd-Bz112c-Jh9lGc">
																	</div>
																	<div class="VfPpkd-Bz112c-J1Ukfc-LhBDec"></div>
																	<img src="../static/svg/more.svg" alt=""
																		class="img_icon">
																</button>
															</div>
															<div jsname="U0exHf" jsslot="">
																<div class="VfPpkd-xl07Ob-XxIAqe VfPpkd-xl07Ob q6oraf P77izf B6Yvkd"
																	jscontroller="ywOR5c"
																	jsaction="keydown:I481le;JIbuQc:j697N(rymPhb);XVaHYd:c9v4Fb(rymPhb);Oyo5M:b5fzT(rymPhb);DimkCe:TQSy7b(rymPhb);m0LGSd:fAWgXe(rymPhb);WAiFGd:kVJJuc(rymPhb);"
																	data-is-hoisted="false"
																	data-should-flip-corner-horizontally="false"
																	data-disable-idom="true" id="UM33ifi15"
																	data-menu-uid="ucj-2"
																	style="transform-origin: center bottom; right: 0px; bottom: 48px; max-height: 771px;">
																	<ul class="VfPpkd-StrnGf-rymPhb DMZ54e"
																		jsname="rymPhb" jscontroller="PHUIyb"
																		jsaction="mouseleave:JywGue; touchcancel:JMtRjd; focus:AHmuwe; blur:O22p3e; keydown:I481le;"
																		role="menu" tabindex="-1"
																		aria-label="Mais ações de avaliação"
																		data-disable-idom="true">
																		<span class="VfPpkd-BFbNVe-bF1uUb NZp2ef"
																			aria-hidden="true"></span>
																		<li class="bO7Z3c VfPpkd-StrnGf-rymPhb-ibnC6b"
																			jsaction="click:o6ZaF;keydown:RDtNu; keyup:JdS61c; focusin:MeMJlc; focusout:bkTmIf;mousedown:teoBgf; mouseup:NZPHBc; mouseenter:SKyDAe; mouseleave:xq3APb; touchstart:jJiBRc; touchmove:kZeBdd; touchend:VfAz8; change:uOgbud;"
																			role="menuitem" tabindex="-1"
																			data-option="2">
																			<span
																				class="VfPpkd-StrnGf-rymPhb-pZXsl"></span>
																			<span jsname="K4r5Ff"
																				class="VfPpkd-StrnGf-rymPhb-b9t22c">Sinalizar
																				como inapropriado</span>
																		</li>
																		<li class="bO7Z3c VfPpkd-StrnGf-rymPhb-ibnC6b"
																			jsaction="click:o6ZaF;keydown:RDtNu; keyup:JdS61c; focusin:MeMJlc; focusout:bkTmIf;mousedown:teoBgf; mouseup:NZPHBc; mouseenter:SKyDAe; mouseleave:xq3APb; touchstart:jJiBRc; touchmove:kZeBdd; touchend:VfAz8; change:uOgbud;"
																			role="menuitem" tabindex="-1"
																			data-option="3">
																			<span
																				class="VfPpkd-StrnGf-rymPhb-pZXsl"></span>
																			<span jsname="K4r5Ff"
																				class="VfPpkd-StrnGf-rymPhb-b9t22c">Mostrar
																				histórico da avaliação</span>
																		</li>
																	</ul>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div class="Jx4nYe">
													<div aria-label="Avaliado com 3 de 5 estrelas" role="img"
														class="iXRFPc">
														<span aria-hidden="true" jsname="fI6EEc" class="F7XJmb"
															:data-number="index+1" :ssk="'1#'+(index+1)"
															v-for="(todo,index) in 5">
															<span class="Z1Dz7b" aria-hidden="true"
																v-if="Number(item.score) >= index + 1">
																<svg xmlns="http://www.w3.org/2000/svg"
																	viewBox="0 0 24 24">
																	<path d="M0 0h24v24H0z" fill="none"></path>
																	<path d="M0 0h24v24H0z" fill="none"></path>
																	<path
																		d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z">
																	</path>
																</svg>
															</span>
															<span class="Wi7KIe" aria-hidden="true"
																v-if="Number(item.score) < index + 1">
																<svg xmlns="http://www.w3.org/2000/svg"
																	viewBox="0 0 24 24">
																	<path d="M0 0h24v24H0z" fill="none"></path>
																	<path d="M0 0h24v24H0z" fill="none"></path>
																	<path
																		d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z">
																	</path>
																</svg>
															</span>
														</span>
													</div>
													<span class="bp9Aid">{{item.time}}</span>
												</div>
											</header>
											<div class="h3YV2d">{{item.content}}</div>
											<div jscontroller="SWD8cc"
												data-review-id="67aca5e7-2138-4f36-afc7-9d42f564e11e"
												data-original-thumbs-up-count="1711">
												<div class="AJTPZc" jsname="J0d7Yd">This review was marked as helpful by {{item.likeCount}} pessoas</div>
												<footer class="Anb3we" jsname="GJNbDf" jsaction="sG2nqb:sY4eAb"
													soy-skip="" ssk="6:oOskHf">
													<div class="NC0Ppb">Did you find this helpful?</div>
													<div jsmodel="b7Ourf" jscontroller="wW2D8b"
														jsaction="rcuQ6b:BZEmlb;JIbuQc:TmtE8e"
														data-expanded-toggles="true">
														<div class="b6SkTb">
															<div role="button"
																id="67aca5e7-2138-4f36-afc7-9d42f564e11e::1"
																class="D3Qfie VfPpkd-ksKsZd-XxIAqe UQI9cd  kofMvc  EFMXQ"
																jscontroller="fdeHmf"
																jsaction="click:cOuCgd; mousedown:UX7yZ; mouseup:lbsD7e; mouseenter:tfO1Yc; mouseleave:JywGue; touchstart:p6p2H; touchmove:FwuNnf; touchend:yfqBxc; touchcancel:JMtRjd; focus:AHmuwe; blur:O22p3e; contextmenu:mg9Pef;jzJ2q:RgMCxe"
																aria-label="Yes" tabindex="0" aria-pressed="false"
																data-single-select="true">
																<div class="Gggmbb" aria-hidden="true"></div>
																<div class="kW9Bj" aria-hidden="true">
																	<span class="c6xzBd jv2yne" jsname="o9vyuc"></span>
																	<span class="ypTNYd" jsname="ODzDMd">Yes</span>
																</div>
															</div>
															<div role="button"
																id="67aca5e7-2138-4f36-afc7-9d42f564e11e::2"
																class="D3Qfie VfPpkd-ksKsZd-XxIAqe UQI9cd  kofMvc  EFMXQ"
																jscontroller="fdeHmf"
																jsaction="click:cOuCgd; mousedown:UX7yZ; mouseup:lbsD7e; mouseenter:tfO1Yc; mouseleave:JywGue; touchstart:p6p2H; touchmove:FwuNnf; touchend:yfqBxc; touchcancel:JMtRjd; focus:AHmuwe; blur:O22p3e; contextmenu:mg9Pef;jzJ2q:RgMCxe"
																aria-label="No" tabindex="0" aria-pressed="false"
																data-single-select="true">
																<div class="Gggmbb" aria-hidden="true"></div>
																<div class="kW9Bj" aria-hidden="true">
																	<span class="c6xzBd jv2yne" jsname="o9vyuc"></span>
																	<span class="ypTNYd" jsname="ODzDMd">No</span>
																</div>
															</div>
														</div>
													</div>
												</footer>
											</div>
										</div>
										<div jsaction="JIbuQc:trigger.hdtuG">
											<div class="u4ICaf">
												<div class="VfPpkd-dgl2Hf-ppHlrf-sM5MNb" data-is-touch-wrapper="true">
													<button
														class="VfPpkd-LgbsSe VfPpkd-LgbsSe-OWXEXe-dgl2Hf ksBjEc lKxP2d LQeN7 aLey0c"
														jscontroller="soHxf"
														jsaction="click:cOuCgd; mousedown:UX7yZ; mouseup:lbsD7e; mouseenter:tfO1Yc; mouseleave:JywGue; touchstart:p6p2H; touchmove:FwuNnf; touchend:yfqBxc; touchcancel:JMtRjd; focus:AHmuwe; blur:O22p3e; contextmenu:mg9Pef;mlnRJb:fLiPzd;"
														data-disable-idom="true">
														<div class="VfPpkd-Jh9lGc"></div>
														<div class="VfPpkd-J1Ukfc-LhBDec"></div>
														<div class="VfPpkd-RLmnJb"></div>
														<span jsname="V67aGc" class="VfPpkd-vQzf8d">See all reviews
                            </span>
													</button>
												</div>
											</div>
										</div>
									</div>


									<c-wiz jsrenderer="C7s1K" jsshadow="" jsdata="deferred-i13"
										data-p="%.@.[&quot;com.coindozer.cner&quot;,7],null,null,null,2,null,null,52]"
										jscontroller="zBPctc" jsaction="hdtuG:OmtWsd;" data-node-index="10;0"
										autoupdate="" jsmodel="hc6Ubd" c-wiz=""><c-data id="i13"
											jsdata=" MrvuD;_;26 fkWDob;com.coindozer.cner,7,,52;7 lxbR8d;_;5 ZR1Qoe;_;27 YjFXEf;_;28 WAAbQe;_;29 GU61mf;_;21"></c-data></c-wiz>
									<c-wiz jsrenderer="q8s33d" jsshadow="" jsdata="deferred-i14" data-p="%.@.]"
										data-node-index="12;0" jsmodel="hc6Ubd" c-wiz="">
										<section class="HcyOxe" jscontroller="NkbkFd">
											<header class=" cswwxf">
												<div class="VMq4uf">
													<div class="EaMWib">
														<h2 class="XfZNbf">What's new</h2>
													</div>
												</div>
											</header>
											<div class="SfzRHd">
												<div v-html="detail.newVersion"></div>
											</div>
										</section>
										<c-data id="i14" jsdata=" W902Ue;_;14"></c-data>
									</c-wiz>
								</div>
								<div class="atOvg">
									<section class="HcyOxe" jscontroller="NkbkFd">
										<div class="SfzRHd"><a class="aA0Vnf" target="_blank"
												href="https://support.google.com/googleplay/?p=report_content">
												<img src="../static/svg/flag.svg" alt="" class="img_icon"
													style="margin-right: 16px;">
												Flag as inappropriate</a></div>
									</section>
								</div>
							</div>

							<div class="o45e4d">
								<c-wiz jsrenderer="Grlxwe" jsshadow="" jsdata="deferred-c65" data-p="%.@.1,1]"
									data-node-index="6;0" autoupdate="" jsmodel="hc6Ubd" c-wiz="">
									<section class="HcyOxe" jscontroller="NkbkFd">
										<header class=" cswwxf">
											<div class="VMq4uf" id="developer-contacts-heading">
												<div class="EaMWib">
													<h2 class="XfZNbf">App support</h2>
												</div>
												<button class="VfPpkd-Bz112c-LgbsSe yHy1rc eT1oJ mN1ivc VxpoF"
													jscontroller="soHxf"
													jsaction="click:cOuCgd; mousedown:UX7yZ; mouseup:lbsD7e; mouseenter:tfO1Yc; mouseleave:JywGue; touchstart:p6p2H; touchmove:FwuNnf; touchend:yfqBxc; touchcancel:JMtRjd; focus:AHmuwe; blur:O22p3e; contextmenu:mg9Pef;mlnRJb:fLiPzd;"
													data-disable-idom="true" aria-label="Collapse"
													aria-controls="developer-contacts" aria-expanded="true"
													style="--mdc-ripple-fg-size: 28px; --mdc-ripple-fg-scale: 1.7142857142857142; --mdc-ripple-left: 10px; --mdc-ripple-top: 10px;">
													<div jsname="s3Eaab" class="VfPpkd-Bz112c-Jh9lGc"></div>
													<div class="VfPpkd-Bz112c-J1Ukfc-LhBDec"></div>
													<img src="../static/svg/expand_less.svg" alt="" class="img_icon">
												</button>
											</div>
										</header>
										<div class="SfzRHd" role="region" id="developer-contacts"
											aria-labelledby="developer-contacts-heading">
											<div class="vfQhrf BxIr0d">
												<div
													class="VfPpkd-WsjYwc VfPpkd-WsjYwc-OWXEXe-INsAgc KC1dQ Usd1Ac AaN0Dd  VVmwY">
													<div class="VfPpkd-EScbFb-JIbuQc" jscontroller="tKHFxf"
														jsaction="click:cOuCgd; mousedown:UX7yZ; mouseup:lbsD7e; mouseenter:tfO1Yc; mouseleave:JywGue; touchstart:p6p2H; touchmove:FwuNnf; touchend:yfqBxc; touchcancel:JMtRjd; focus:AHmuwe; blur:O22p3e; contextmenu:mg9Pef;">
														<a class="Si6A0c RrSxVb" target="_blank"
															href="mailto:noreply@info.funbr.com"
															aria-label="Support email mailto:noreply@info.funbr.com will open in a new window or tab.">
															<img src="../static/email.png" alt="" class="sup_icon">
															<div class="pZ8Djf">
																<div class="xFVDSb">Support email</div>
																<div class="pSEeg">{{detail.email}}</div>
															</div>
														</a>
														<div class="VfPpkd-FJ5hab"></div>
													</div>
													<span class="VfPpkd-BFbNVe-bF1uUb NZp2ef" aria-hidden="true"></span>
												</div>

												<div
													class="VfPpkd-WsjYwc VfPpkd-WsjYwc-OWXEXe-INsAgc KC1dQ Usd1Ac AaN0Dd  VVmwY">
													<div class="VfPpkd-EScbFb-JIbuQc" jscontroller="tKHFxf"
														jsaction="click:cOuCgd; mousedown:UX7yZ; mouseup:lbsD7e; mouseenter:tfO1Yc; mouseleave:JywGue; touchstart:p6p2H; touchmove:FwuNnf; touchend:yfqBxc; touchcancel:JMtRjd; focus:AHmuwe; blur:O22p3e; contextmenu:mg9Pef;">
														<a class="Si6A0c RrSxVb" target="_blank"
															href="mailto:noreply@info.funbr.com"
															aria-label="Support email mailto:noreply@info.funbr.com will open in a new window or tab.">
															<img src="../static/location.png" alt="" class="sup_icon">
															<div class="pZ8Djf">
																<div class="xFVDSb">Address</div>
																<div class="pSEeg">{{detail.address}}</div>
															</div>
														</a>
														<div class="VfPpkd-FJ5hab"></div>
													</div>
													<span class="VfPpkd-BFbNVe-bF1uUb NZp2ef" aria-hidden="true"></span>
												</div>


												<div
													class="VfPpkd-WsjYwc VfPpkd-WsjYwc-OWXEXe-INsAgc KC1dQ Usd1Ac AaN0Dd  VVmwY">
													<div class="VfPpkd-EScbFb-JIbuQc" jscontroller="tKHFxf"
														jsaction="click:cOuCgd; mousedown:UX7yZ; mouseup:lbsD7e; mouseenter:tfO1Yc; mouseleave:JywGue; touchstart:p6p2H; touchmove:FwuNnf; touchend:yfqBxc; touchcancel:JMtRjd; focus:AHmuwe; blur:O22p3e; contextmenu:mg9Pef;">
														<a class="Si6A0c RrSxVb" target="_blank"
															:href="detail.privacyUrl"
															aria-label="Privacy Policy https://sites.google.com/view/funbrapp/home will open in a new window or tab.">
															<img src="../static/shield.png" alt="" class="sup_icon">
															<div class="pZ8Djf">
																<div class="xFVDSb">Privacy Policy</div>
															</div>
														</a>
														<div class="VfPpkd-FJ5hab"></div>
													</div>
													<span class="VfPpkd-BFbNVe-bF1uUb NZp2ef" aria-hidden="true"></span>
												</div>
											</div>
										</div>
									</section>
									<c-data id="c65" jsdata=" W902Ue;_;$65"></c-data>
								</c-wiz>
							</div>

							<div class="OaCuEc">
								<section class="HcyOxe" jscontroller="NkbkFd">
									<div class="SfzRHd"><a class="aA0Vnf" target="_blank"
											href="https://support.google.com/googleplay/?p=report_content">
											<img src="../static/svg/flag.svg" alt="" class="img_icon"
												style="margin-right: 16px;">
											Flag as
											inappropriate</a></div>
								</section>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>


		<footerBox></footerBox>
	</div>
</template>

<script>
import { Toast } from "vant";
	export default {
		data() {
			return {
        playUrl:'',
        playLoading:1,
        pwaModel:false,
				detail: '',
				scoreList: [],
				evaluate: []
			}
		},
		created() {
      // 检测当前环境是pwa
      if (window.matchMedia("(display-mode: standalone)").matches) {
        this.pwaModel=true
        // 在PWA环境中执行的代码
        if(localStorage.getItem("detail")){
          let url = JSON.parse(localStorage.getItem("detail"))
          window.location.replace(url.downloadUrl.split("pwa-")[1])
        }
        // window.location.href=this.detail.downloadUrl

      } else {
        // 不在PWA环境中执行的代码
        console.log("不是pwa环境");
      }
			this.init()
		},
    activated(){
      // 检测当前环境是pwa
      if (window.matchMedia("(display-mode: standalone)").matches) {
        this.pwaModel=true
        // 在PWA环境中执行的代码
        let url = JSON.parse(localStorage.getItem("detail"))
        window.location.replace(url.downloadUrl.split("pwa-")[1])

      } else {
        // 不在PWA环境中执行的代码
        console.log("不是pwa环境");
      }
    },
    mounted() {
      // 检测当前环境是pwa
      if (window.matchMedia("(display-mode: standalone)").matches) {
        this.pwaModel=true
        // 在PWA环境中执行的代码
        if(localStorage.getItem("detail")){
          let url = JSON.parse(localStorage.getItem("detail"))
          window.location.replace(url.downloadUrl.split("pwa-")[1])
        }
       // window.location.href=this.detail.downloadUrl

      } else {
        // 不在PWA环境中执行的代码
        console.log("不是pwa环境");
      }
    },
    methods: {
      isPWA() {
        // 检测Service Worker注册API是否存在
        if ('serviceWorker' in navigator) {
          // 检测当前页面是否由Service Worker控制
          navigator.serviceWorker.getRegistrations().then(registrations => {
            if (registrations.length > 0) {
              console.log('当前环境是PWA');
              return true; // 支持Service Worker
              // 处理逻辑
            } else {
              console.log('当前环境不是PWA');
              // 处理逻辑
            }
          });
        }
        return false; // 不支持Service Worker
      },
      installClick(){
        let that = this
        //PWA环境
        if(this.detail.downloadUrl && this.detail.downloadUrl=='javascript:void(0)'){
          if (this.$store.state.pwa != "0") {
            try {
              this.$store.state.pwa.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                  // Toast("Under installation");
                  if(that.playLoading==1){
                    that.playLoading=2
                    setTimeout(function() {
                      // 需要执行的代码
                      that.playLoading=3
                    }, 5000);
                  }
                  // 用户接受了安装提示，但这里并不表示安装已经完成
                }
              });
            }catch (e){

            }
            this.$store.state.pwa.prompt();
          } else {
            that.playLoading=3
          }
        }
        try {
          if (window.fbq) {
            window.fbq("trackCustom", "register");
          }
        }catch (e){

        }
        this.api.getInstall({domainId:this.detail.mainId})
      },
			init() {
				let url = window.location.href
				if (url.indexOf('?') != -1) {
					url = url.split('?')[0]
				}
				console.log(url)
				let params = {
					url: url
				}
				this.api.getConfig(params).then(res => {
					if (res.success) {
						this.detail = res.data
            localStorage.setItem("detail",JSON.stringify(res.data))
            if(this.detail.downloadUrl && this.detail.downloadUrl.indexOf("pwa")!=-1){
              this.playUrl=this.detail.downloadUrl.split("-")[1]
              this.detail.downloadUrl='javascript:void(0)'
            }
						document.title = res.data.name + ' - Apps on Google Play'
						let data = res.data.scoreJson
						data = data.split(',')
						for (let i in data) {
							this.scoreList = this.scoreList.concat(data[i].split(':')[1])
						}
            this.api.getClick({domainId:this.detail.mainId})
					}
				})

				this.api.getEvaluate(params).then(res => {
					if (res.success) {
						this.evaluate = res.data
					}
				})
			}
		}
	}
</script>

<style scoped>
  .pwaok{
    display: none;
  }
	@import '../assets/main.css';


	.T4LgNb {
		margin-top: 56px;
	}

	.install {
		width: 100%;
		height: 36px;
		text-align: center;
		font-size: 14px;
		font-weight: 500;
		border-radius: 8px;
		background-color: #01875f;
		font: inherit;
		line-height: 36px;
		margin-bottom: 6px;
	}

	.install a {
		color: #fff;
		font: inherit;
	}

	.share,
	.add {
		display: flex;
		align-items: center;
		font: inherit;
		cursor: pointer;
		margin-right: 8px;
		padding: 8px 12px;
		border-radius: 8px;
	}

	.share .icon,
	.add .icon {
		width: 20px;
	}

	.share span,
	.add span {
		color: #01875f;
		font: inherit;
		padding-left: 8px;
	}

	.share:hover,
	.add:hover {
		background-color: rgb(246, 250, 254);
	}

	.share:hover span,
	.add:hover span {
		color: #056449;
	}

	.devices {
		width: 20px;
		margin-right: 12px;
	}

	.VMq4uf {
		position: relative;
	}

	.sup_icon {
		width: 18px;
		height: 18px;
		margin-right: 16px;
	}

	.img_icon {
		width: 22px;
		height: 22px;
	}

	.fit {
		border: 1px solid #000;
		font-size: 12px;
		color: #000;
		line-height: 12px;
		letter-spacing: -1.5px;
		font-weight: 500;
	}

	.F7XJmb {
		margin-right: 2.5px;
	}

	@media screen and (min-width: 840px) {
		.T4LgNb {
			margin-top: 64px;
		}

		.install {
			width: 200px;
			height: 48px;
			line-height: 48px;
			margin-bottom: 0;
		}
	}
</style>
